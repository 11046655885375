import React from 'react';
import style from './contractApprowe.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {auctionsGetAwards, auctionsGetAwardsId, changeAwardRejectPopUpStatus, changeAwardErrorPopUpStatus, changeAwardApprowePopUpStatus} from '../../../../redux/actions/awards/awards.js';
import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
} from '../../../../redux/actions/contracts/contracts.js';

import {setUniversalError, changeLoader} from '../../../../redux/actions.js';


const DeleteBidDocument = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>Затвердити контракт</div>
                {createView(props)}
                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            //props.changeContractApprowePopUpStatus(null)
                        }
                    }
                >
                    Затвердити
                </div>
                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            props.changeContractApprowePopUpStatus(null)
                        }
                    }
                >
                    Закрити
                </div>
            </div>
        </div>
    )
}

/*
Загрузка документа
Разблокировка кнопки удалить
Отсылка картинки
Подключение ее к документу
Создание документа
Отсылка реджекта аварда с подключением документа
*/


function createView(props){
    let tmp = null;
    tmp = (
        <div className={style.documentsForm}>
            <div className={style.groupElem}>
                {buttonCreateImg(props)}
            </div>
        </div>
    )
    return tmp
}

function buttonCreateImg(props){
    let fileElem = <span className={style.input__file_button_text}>
            <span>Додати документ</span>
        </span>
    return (
        <div className={style.formLine}>
            <form 
                onSubmit={(e) => {
                    handleSubmit(e, props)
                }}
                id="addFileForm"
            >
                <div className={style.input__wrapper}>
                    <input
                        name="file"
                        type="file"
                        id="input__fileDel"
                        className={style.input + ' ' + style.input__file}
                    />
                </div>
                <div>
                    <div>
                        <TextField
                            label="Назва договору"
                            value={props.timberContractPut.title}
                            onChange={(e) => {
                                props.setContractTitle(e.target.value)
                            }}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                    <div>
                        <TextField
                            label="Опис договору"
                            value={props.timberContractPut.description}
                            onChange={(e) => {
                                props.setContractDescription(e.target.value)
                            }}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                    <div>
                        <TextField
                            label="Номер договору"
                            value={props.timberContractPut.contractNumber}
                            onChange={(e) => {
                                props.setContractContractNumber(e.target.value)
                            }}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                    <div>
                        <TextField
                            id="datetime-local"
                            label="Дата початку дії договру"
                            type="datetime-local"
                            defaultValue={props.timberContractPut.contractTime.startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={(event)=>{
                                props.setContractStartDate(event.target.value)
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            id="datetime-local"
                            label="Дата завершення дії договору"
                            type="datetime-local"
                            defaultValue={props.timberContractPut.contractTime.endDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={(event)=>{
                                props.setContractEndDate(event.target.value)
                            }}
                        />
                    </div>


                    <div className={style.group}>
                        <div>Предоплата</div>
                        <div className={style.groupElem}>
                            <div>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    label="Валюта"
                                    value={props.timberContractPut.xValueUAH.currency}
                                    onChange={(event)=>{
                                        props.setContractCurrency(event.target.value)
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText="Выберите тип валюты"
                                    variant="outlined"
                                >
                                    <option key={1} selected value="UAH">UAH</option>
                                    <option key={2} value="USD">USD</option>
                                    <option key={3} value="EUR">EUR</option>
                                    <option key={4} value="eurocent">eurocent</option>
                                </TextField>
                            </div>
                            <div>
                                <TextField
                                    label="Загальна вартість договору в грн"
                                    value={props.timberContractPut.xValueUAH.amount}
                                    onChange={(event) => {
                                        props.setContractAmount(event.target.value)
                                    }}
                                    variant="outlined"
                                    helperText=" "
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <TextField
                            id="datetime-local"
                            label="Дата предоплаты"
                            type="datetime-local"
                            defaultValue={props.timberContractPut.contractTime.dateFrom}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={(event)=>{
                                props.setContractDateFrom(event.target.value)
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            id="datetime-local"
                            label="Дата підписання договору"
                            type="datetime-local"
                            value={props.timberContractPut.dateSigned}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={(e)=>{
                                props.setContractDateSigned(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    className={style.popup_message_button}
                >
                    Зберегти
                </button>
            </form>
        </div>
    )
}

function handleSubmit(e, props){
    e.preventDefault();
    promisUploadFiles(props)
}

async function promisUploadFiles(props){
    const addFileInp = document.getElementById('input__fileDel');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', 'contractSigned')
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
        const response1 = await fetch(endPoint1, {
            method: "POST",
            body: JSON.stringify({
                title: 'Затвердження контракту',
                description: 'Затвердження контракту',
                documentType: 'contractSigned',
                language: 'uk',
                documentOf: 'contract',
                token: json.jwt
            }),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response1 === undefined || response1 === 'undefined'){
        }else{
            const json1 = await response1.json()
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/documents/${json1.id}`
            const response2 = await fetch(endPoint2, {
                method: "POST",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const endPoint4 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}`
                const response4 = await fetch(endPoint4, {
                    method: "PUT",
                    body: JSON.stringify({
                        'title': props.timberContractPut.title,
                        'description': props.timberContractPut.description,
                        'contractNumber': props.timberContractPut.contractNumber,
                        'x_prepaymentDetailsValue': {
                            'currency': props.timberContractPut.xValueUAH.currency,
                            'amount': props.timberContractPut.xValueUAH.amount
                        },
                        'x_prepaymentDetailsDate': props.timberContractPut.contractTime.dateFrom,
                        'contractTime': {
                            'startDate': props.timberContractPut.contractTime.startDate,
                            'endDate': props.timberContractPut.contractTime.endDate
                        },
                        'dateSigned': props.timberContractPut.dateSigned
                    }),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }

                }).catch(console.error)
                if(response4 === undefined || response4 === 'undefined'){
                }else{
                    const json4 = await response4.json()
                    //const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}`
                    const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/approve/set_status/active`
                    const response3 = await fetch(endPoint3, {
                        method: "POST",
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    //возможно ненужен апрув
                    if(response3 === undefined || response3 === 'undefined'){
                        props.changeLoader(false)
                    }else{
                        const json3 = await response3.json()
                        if(json3.hasOwnProperty('message') === true){
                            props.changeContractErrorPopUpStatus(json3)
                            props.changeLoader(false)
                        }else{
                            props.changeContractApprowePopUpStatus(null)
                            props.changeLoader(false)
                        }
                    }
                }
            }
        }
    }
    props.changeLoader(false)
}






const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardApprowe: state.start.popUpAwardApprowe,
        popUpContractApprowe: state.start.popUpContractApprowe,
        timberContractPut: state.start.timberContractPut,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardApprowePopUpStatus,
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,
    
    setContractTitle,
    setContractDescription,
    setContractContractNumber,


    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    setUniversalError,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBidDocument)